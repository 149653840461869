import React, { useState,useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios'; // Make sure axios is imported

// Replace 'backgroundImage' with your online image URL
const backgroundImage = 'https://w0.peakpx.com/wallpaper/445/554/HD-wallpaper-green-paddy-field-ultra-nature-landscape-green-scenery-field-rice-paddy.jpg';

function AdminPage() {
  const navigate = useNavigate();
  const [showLoginForm, setShowLoginForm] = useState(true);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [signupFormData, setSignupFormData] = useState({ email: '', password: '', confirmPassword: '' });
  const [signupErrors, setSignupErrors] = useState({ email: '', confirmPassword: '' });
  const [loginFormData, setLoginFormData] = useState({ email: '', password: '' });
  const [loginErrors, setLoginErrors] = useState({ email: '' });
  const [reenteredPassword, setReenteredPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [resetEmail, setResetEmail] = useState('');
  const [otp, setOtp] = useState('');
  const [enteredOtp, setEnteredOtp] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [currentStep, setCurrentStep] = useState('email');
  const [users, setUsers] = useState([]);
  const [addingUser, setAddingUser] = useState(false);
  const [newUserEmail, setNewUserEmail] = useState('');
  const [newUserPassword, setNewUserPassword] = useState('');
  const [showUserList, setShowUserList] = useState(false);

  const handleToggleForm = () => {
    setShowLoginForm(!showLoginForm);
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
        const response = await axios.post('https://sbrm-routing-078364e06806.herokuapp.com/api/userlogin', { email, password });
        console.log('Login Response:', response.data);

        // Handle successful login (e.g., store user token, redirect, etc.)
        localStorage.setItem('isUserLoggedIn', true); // Changed to 'isUserLoggedIn' for clarity
        navigate('/dashboard');
    } catch (error) {
        console.error('Login Error:', error.response?.data?.message || 'Login failed');
        // Replace setErrorMessage with an alert message
        alert('Invalid email or password. Please try again.');
    }
};

  const handleSignup = async (e) => {
    e.preventDefault();
  
    // Basic form validation
    const { email, password, confirmPassword } = signupFormData;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  
    if (!email || !password || !confirmPassword) {
      alert('Email and password are required');
      return;
    }
  
    if (!emailRegex.test(email)) {
      alert('Invalid email format');
      return;
    }
  
    if (password !== confirmPassword) {
      setSignupErrors({  confirmPassword: 'Passwords do not match' });
      return;
    }
  
    // Strong password validation
    const strongPasswordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*]).{8,}$/;
    if (!strongPasswordRegex.test(password)) {
      alert('Password must contain at least 8 characters including at least one number, one lowercase letter, one uppercase letter, and one special character (!@#$%^&*).');
      return;
    }
  
    try {
      const response = await axios.post('https://sbrm-routing-078364e06806.herokuapp.com/api/signup', signupFormData);
      console.log('Signup Response:', response.data);
      alert('Registered successfully');
      // Handle successful signup (e.g., show success message, redirect, etc.)
    } catch (error) {
      console.error('Signup Error:', error.response?.data?.message || 'Signup failed');
      // Check if the error response contains information about the email being already in use
      if (error.response?.data?.message === 'User already exists') {
        setSignupErrors({ ...signupErrors, email: 'User already exists' });
      } else {
        // Handle other types of signup errors
        setSignupErrors({ ...signupErrors, general: 'Signup failed. Please try again.' });
      }
    }
  };
  
  useEffect(() => {
    fetchUsers();
}, []); // Run once on component mount

const fetchUsers = () => {
    axios.get('https://sbrm-routing-078364e06806.herokuapp.com/api/users')
        .then((response) => {
            // Handle successful fetch
            console.log('Users fetched successfully:', response.data);
            setUsers(response.data);
        })
        .catch((error) => {
            // Handle error
            console.error('Error fetching users:', error.response?.data || error.message);
        });
};

  
  const handleAddUser = () => {
    if (newUserEmail && newUserPassword) {
      // Email validation
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailPattern.test(newUserEmail)) {
        setErrorMessage('Please enter a valid email address.');
        return;
      }
  
      // Strong password validation
      const strongPasswordPattern = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*]).{8,}$/;
      if (!strongPasswordPattern.test(newUserPassword)) {
        alert('Password must contain at least 8 characters including at least one number, one lowercase letter, one uppercase letter, and one special character (!@#$%^&*).');
        return;
      }
  
      const newUser = { email: newUserEmail, password: newUserPassword };
  
      axios.post('https://sbrm-routing-078364e06806.herokuapp.com/api/create', newUser)
        .then((response) => {
          // Handle successful response
          console.log('User created successfully');
          setUsers([...users, newUser]);
          setNewUserEmail('');
          setNewUserPassword('');
          setAddingUser(false);
          alert('User added successfully');
        })
        .catch((error) => {
          // Handle error
          console.error('Error creating user:', error.response?.data || error.message);
          setErrorMessage('Error creating user. Please try again.');
        });
    } else {
      setErrorMessage('Please fill in all fields.');
    }
  };
  
  
  
  const handleRemoveUser = (userEmail) => {
    axios.delete(`https://sbrm-routing-078364e06806.herokuapp.com/api/delete/${userEmail}`)
      .then((response) => {
        // Handle successful deletion
        console.log('User deleted successfully');
        setUsers(users.filter(user => user.email !== userEmail));
      })
      .catch((error) => {
        // Handle error
        console.error('Error deleting user:', error.response.data);
      });
  };

  const handleForgotPassword = () => {
    setShowForgotPassword(true);
  };

  const handleSendOtp = async () => {
    // Check if resetEmail is empty or not a valid email format
    if (!resetEmail || !/\S+@\S+\.\S+/.test(resetEmail)) {
        setErrorMessage('Please enter a valid email address.');
        return;
    }

    try {
        const response = await axios.post('https://sbrm-routing-078364e06806.herokuapp.com/api/sendEmailOtp1', {
            email: resetEmail,
        });

        const data = response.data;

        if (data.success) {
            setOtp(data.otp); // Save the OTP from the server for debugging
            console.log('OTP sent by server:', data.otp); // Log OTP for debugging
            setCurrentStep('otp');
        } else {
            setErrorMessage('Failed to send OTP. Please try again.');
        }
    } catch (error) {
        console.error('Error sending OTP:', error);
        setErrorMessage('Failed to send OTP. Please try again.');
    }
};


const handleVerifyOtp = async () => {
    try {
        console.log('OTP entered by user:', enteredOtp); // Log entered OTP for debugging
        console.log('OTP stored from server:', otp); // Log stored OTP for debugging

        if (!enteredOtp || !otp) {
            setErrorMessage('OTP is missing. Please try again.');
            return;
        }

        const response = await axios.post('https://sbrm-routing-078364e06806.herokuapp.com/api/verifyEmailOtp1', {
            enteredOtp: enteredOtp,
            email: resetEmail, // Ensure email is included
        });

        if (response.data.success) {
            setCurrentStep('resetPassword');
        } else {
            setErrorMessage('Invalid OTP. Please try again.');
        }
    } catch (error) {
        console.error('Error verifying OTP:', error);
        setErrorMessage('Invalid OTP. Please try again.');
    }
};

  

const handleResetPassword = async () => {
  if (newPassword !== confirmNewPassword) {
      setErrorMessage('Passwords do not match');
      return;
  }

  try {
      const response = await axios.post('https://sbrm-routing-078364e06806.herokuapp.com/api/resetPassword', {
          email: resetEmail,
          newPassword: newPassword,
      });

      if (response.data.success) {
          setShowForgotPassword(false);
          setErrorMessage('');
          // Add an alert message for successful password reset
          alert('Password reset successful');
      } else {
          setErrorMessage('Failed to reset password. Please try again.');
      }
  } catch (error) {
      console.error('Error resetting password:', error);
      setErrorMessage('Failed to reset password. Please try again.');
  }
};


  return (
    <div style={containerStyle}>
      <div style={formContainerStyle}>
        <h1 style={titleStyle}>Admin</h1>
        {showLoginForm ? (
          <>
            <h2 style={subtitleStyle}>Login</h2>
            <input type="email" placeholder="Email" style={inputStyle} value={email} onChange={(e) => setEmail(e.target.value)} />
            <input type="password" placeholder="Password" style={inputStyle} value={password} onChange={(e) => setPassword(e.target.value)} />
            {errorMessage && <p style={{ color: 'red', textAlign: 'center' }}>{errorMessage}</p>}
            <button style={buttonStyle} onClick={handleLogin}>Login</button>
            <p style={toggleTextStyle}>Don't have an account? <button style={linkButtonStyle} onClick={handleToggleForm}>Register</button></p>
            <p style={toggleTextStyle}><button style={linkButtonStyle} onClick={handleForgotPassword}>Forgot Password?</button></p>
          </>
        ) : (
          <>
            <h2 style={subtitleStyle}>Register</h2>
            <input type="email" placeholder="Email" style={{ width: '100%', marginBottom: '15px', padding: '10px', border: '1px solid #ccc', borderRadius: '5px' }} value={signupFormData.email} onChange={(e) => setSignupFormData({ ...signupFormData, email: e.target.value })} />
            {signupErrors.email && <p>{signupErrors.email}</p>}
            <input type="password" placeholder="Password" style={{ width: '100%', marginBottom: '15px', padding: '10px', border: '1px solid #ccc', borderRadius: '5px' }} value={signupFormData.password} onChange={(e) => setSignupFormData({ ...signupFormData, password: e.target.value })} />
            <input type="password" placeholder="Re-enter Password" style={{ width: '100%', marginBottom: '15px', padding: '10px', border: '1px solid #ccc', borderRadius: '5px' }} value={signupFormData.confirmPassword} onChange={(e) => setSignupFormData({ ...signupFormData, confirmPassword: e.target.value })} />
            {signupErrors.confirmPassword && <p>{signupErrors.confirmPassword}</p>}
            {errorMessage && <p style={{ color: 'red', textAlign: 'center' }}>{errorMessage}</p>}
            <button style={buttonStyle} onClick={handleSignup}>Sign Up</button>
            <p style={toggleTextStyle}>Already have an account? <button style={linkButtonStyle} onClick={handleToggleForm}>Login</button></p>
            <p style={toggleTextStyle}><button style={linkButtonStyle} onClick={() => setAddingUser(true)}>Add User</button></p>
          </>
        )}
      </div>

      <div>
            <button
                style={{
                    backgroundColor: '#007bff',
                    color: 'white',
                    border: 'none',
                    padding: '8px 16px',
                    cursor: 'pointer',
                }}
                onClick={() => setShowUserList(!showUserList)}
            >
                {showUserList ? 'Hide User List' : 'Show User List'}
            </button>

            {showUserList && (
                <div
                    style={{
                        position: 'fixed',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        backgroundColor: 'white',
                        padding: '20px',
                        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
                        borderRadius: '5px',
                        width:'500px'
                    }}
                >
                    <h2>User List</h2>
                    <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                        <thead>
                            <tr>
                                <th style={{ border: '1px solid #ddd', padding: '8px' }}>User</th>
                                <th style={{ border: '1px solid #ddd', padding: '8px' }}>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {users.map(user => (
                                <tr key={user.email}>
                                    <td style={{ border: '1px solid #ddd', padding: '8px' }}>{user.email}</td>
                                    <td style={{ border: '1px solid #ddd', padding: '8px' }}>
                                        <button
                                            style={{
                                                backgroundColor: 'red',
                                                color: 'white',
                                                border: 'none',
                                                padding: '4px 8px',
                                                cursor: 'pointer',
                                            }}
                                            onClick={() => handleRemoveUser(user.email)}
                                        >
                                            Remove
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <button
                        style={{
                            backgroundColor: '#dc3545',
                            color: 'white',
                            border: 'none',
                            padding: '8px 16px',
                            cursor: 'pointer',
                            marginTop: '16px',
                        }}
                        onClick={() => setShowUserList(false)}
                    >
                        Close
                    </button>
                </div>
            )}
        </div>

      {addingUser && (
        <div style={modalOverlayStyle}>
          <div style={modalContentStyle}>
            <h2 style={subtitleStyle}>Add User</h2>
            <input type="email" placeholder="Email" style={inputStyle} value={newUserEmail} onChange={(e) => setNewUserEmail(e.target.value)} />
            <input type="password" placeholder="Password" style={inputStyle} value={newUserPassword} onChange={(e) => setNewUserPassword(e.target.value)} />
            {errorMessage && <p style={{ color: 'red', textAlign: 'center' }}>{errorMessage}</p>}
            <button style={buttonStyle} onClick={handleAddUser}>Add User</button><br/>
            <br/>
            
            <button style={buttonStyle} onClick={() => setAddingUser(false)}>Cancel</button>
          </div>
        </div>
      )}

      {showForgotPassword && (
      <div style={modalOverlayStyle}>
        <div style={modalContentStyle}>
          {currentStep === 'email' && (
            <>
              <h2 style={subtitleStyle}>Reset Password</h2>
              <input
                type="email"
                placeholder="Enter registered email"
                style={inputStyle}
                value={resetEmail}
                onChange={(e) => setResetEmail(e.target.value)}
              />
              <button style={buttonStyle} onClick={handleSendOtp}>Send OTP</button>
            </>
          )}
          {currentStep === 'otp' && (
            <>
              <h2 style={subtitleStyle}>Enter OTP</h2>
              <input
                type="text"
                placeholder="Enter OTP"
                style={inputStyle}
                value={enteredOtp}
                onChange={(e) => setEnteredOtp(e.target.value)}
              />
              <button style={buttonStyle} onClick={handleVerifyOtp}>Verify OTP</button>
            </>
          )}
          {currentStep === 'resetPassword' && (
            <>
              <h2 style={subtitleStyle}>Reset Password</h2>
              <input
                type="password"
                placeholder="New Password"
                style={inputStyle}
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
              <input
                type="password"
                placeholder="Confirm New Password"
                style={inputStyle}
                value={confirmNewPassword}
                onChange={(e) => setConfirmNewPassword(e.target.value)}
              />
              <button style={buttonStyle} onClick={handleResetPassword}>Apply</button>
            </>
          )}
          {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
        </div>
      </div>
      )}
    </div>
  );
}

const containerStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100vh',
  backgroundImage: `url(${backgroundImage})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
};

const formContainerStyle = {
  width: '350px',
  padding: '40px',
  borderRadius: '10px',
  backgroundColor: 'rgba(255, 255, 255, 0.8)',
  boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.1)',
};

const titleStyle = {
  marginBottom: '30px',
  color: '#333',
  textAlign: 'center',
};

const subtitleStyle = {
  marginBottom: '20px',
  color: '#007bff',
  textAlign: 'center',
};

const inputStyle = {
  width: '100%',
  marginBottom: '15px',
  padding: '10px',
  border: '1px solid #ccc',
  borderRadius: '5px',
};

const buttonStyle = {
  width: '100%',
  padding: '10px',
  border: 'none',
  borderRadius: '5px',
  backgroundColor: '#007bff',
  color: '#fff',
  cursor: 'pointer',
  transition: 'background-color 0.3s',
};

const toggleTextStyle = {
  marginTop: '20px',
  textAlign: 'center',
  color: '#666',
};

const linkButtonStyle = {
  background: 'none',
  border: 'none',
  color: '#007bff',
  cursor: 'pointer',
  textDecoration: 'underline',
};

const toggleButtonStyle = {
  marginTop: '20px',
  padding: '10px 20px',
  border: 'none',
  borderRadius: '5px',
  backgroundColor: '#007bff',
  color: '#fff',
  cursor: 'pointer',
  transition: 'background-color 0.3s',
};

const userListContainerStyle = {
  marginTop: '20px',
  width: '350px',
  padding: '20px',
  borderRadius: '10px',
  backgroundColor: 'rgba(255, 255, 255, 0.8)',
  boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.1)',
};

const userListStyle = {
  listStyleType: 'none',
  padding: '0',
};

const userItemStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  padding: '10px',
  borderBottom: '1px solid #ccc',
};

const removeButtonStyle = {
  padding: '5px 10px',
  border: 'none',
  borderRadius: '5px',
  backgroundColor: '#ff4d4d',
  color: '#fff',
  cursor: 'pointer',
  transition: 'background-color 0.3s',
};

const modalOverlayStyle = {
  position: 'fixed',
  top: '0',
  left: '0',
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

const modalContentStyle = {
  width: '320px',
  padding: '20px',
  borderRadius: '10px',
  backgroundColor: 'rgba(255, 255, 255, 1)',
  boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.1)',
  textAlign: 'center',
};

export default AdminPage;
