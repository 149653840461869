import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartBar, faListAlt, faUsers, faTasks, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import OrderDetails from './OrderDetails';
import CustomerDetails from './CustomerDetails';
import AssignOrder from './AssignOrder';
import Dash from './Dash';
import './styles.css'; // Import your CSS file for styling

function DashboardPage() {
  const navigate = useNavigate();
  const [selectedNavItem, setSelectedNavItem] = useState(() => {
    return localStorage.getItem('selectedNavItem') || 'dashboard';
  });
  const [showLogoutModal, setShowLogoutModal] = useState(false); // State to control the logout modal
  const [loading, setLoading] = useState(false); // State to control loading indicator

  useEffect(() => {
    const isAdminLoggedIn = localStorage.getItem('isAdminLoggedIn');
    const isUserLoggedIn = localStorage.getItem('isUserLoggedIn');
    
    if (!isAdminLoggedIn && !isUserLoggedIn) {
      navigate('/');
    }
  }, [navigate]);

  useEffect(() => {
    localStorage.setItem('selectedNavItem', selectedNavItem);
  }, [selectedNavItem]);

  const handleLogout = () => {
    setShowLogoutModal(true); // Show the logout confirmation modal
  };

  const handleConfirmLogout = () => {
    setLoading(true); // Show loading indicator while logging out
    localStorage.removeItem('isAdminLoggedIn');
    localStorage.removeItem('isUserLoggedIn');
    setTimeout(() => {
      navigate('/');
      setLoading(false); // Hide loading indicator after logout
    }, 1000); // Simulate logout delay for 1 second
  };

  const handleCancelLogout = () => {
    setShowLogoutModal(false); // Hide the logout confirmation modal
  };

  const handleNavItemSelect = (item) => {
    setSelectedNavItem(item);
  };

  return (
    <div className="dashboard-container">
      <div className="sidebar">
        <div className="company-logo">
          <img src="https://i.im.ge/2024/02/29/g40RWP.SriBalajiRicemill-image.jpg" alt="Company Logo" className="logo-image" />
          <h2 className="logo-text">SBRM</h2>
        </div>
        
        <ul className="nav-menu">
          <li>
            <button
              className={`nav-item ${selectedNavItem === 'dashboard' ? 'active' : ''}`}
              onClick={() => handleNavItemSelect('dashboard')}
            >
              <FontAwesomeIcon icon={faChartBar} className="icon" />
              Dashboard
            </button>
          </li>
          <li>
            <button
              className={`nav-item ${selectedNavItem === 'order-details' ? 'active' : ''}`}
              onClick={() => handleNavItemSelect('order-details')}
            >
              <FontAwesomeIcon icon={faListAlt} className="icon" />
              Order Details
            </button>
          </li>
          <li>
            <button
              className={`nav-item ${selectedNavItem === 'customer-details' ? 'active' : ''}`}
              onClick={() => handleNavItemSelect('customer-details')}
            >
              <FontAwesomeIcon icon={faUsers} className="icon" />
              Cust Details
            </button>
          </li>
          <li>
            <button
              className={`nav-item ${selectedNavItem === 'assign-order' ? 'active' : ''}`}
              onClick={() => handleNavItemSelect('assign-order')}
            >
              <FontAwesomeIcon icon={faTasks} className="icon" />
              Assign Order
            </button>
          </li>
          <li>
          <button className="logout-btn" onClick={handleLogout}>
          <FontAwesomeIcon icon={faSignOutAlt} className="icon" />
          Logout
        </button>
          </li>
        </ul>
        
      </div>

      <div className="main-content">
        {loading ? (
          <div className="loading-indicator">Loading...</div>
        ) : (
          <>
            {selectedNavItem === 'dashboard' && <Dash />}
            {selectedNavItem === 'order-details' && <OrderDetails />}
            {selectedNavItem === 'customer-details' && <CustomerDetails />}
            {selectedNavItem === 'assign-order' && <AssignOrder />}
          </>
        )}
      </div>

      {/* Logout Confirmation Modal */}
      {showLogoutModal && (
        <div className="modal-overlay">
          <div className="modal">
            <h3>Are you sure you want to logout?</h3>
            <div className="modal-buttons">
              <button className="confirm-btn" onClick={handleConfirmLogout}>Yes</button>
              <button className="cancel-btn" onClick={handleCancelLogout}>No</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default DashboardPage;
