import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios'; // Make sure axios is imported

// Define the URL of your background image
const backgroundImage = 'https://i.pinimg.com/1200x/d2/47/b5/d247b58adacefb0090554fd97f0c166a.jpg';

function UserPage() {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://sbrm-routing-078364e06806.herokuapp.com/api/userlogin', { email, password });
      console.log('Login Response:', response.data);
  
      // Handle successful login (e.g., store user token, redirect, etc.)
      localStorage.setItem('isUserLoggedIn', true); // Changed to 'isUserLoggedIn' for clarity
      navigate('/dashboard');
    } catch (error) {
      console.error('Login Error:', error.response?.data?.message || 'Login failed');
      setErrorMessage('Invalid email or password. Please try again.');
    }
  };
  return (
    <div style={{ 
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100vh',
      backgroundImage: `url(${backgroundImage})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center'
    }}>
      <div style={{ 
        width: '350px',
        padding: '40px',
        borderRadius: '10px',
        backgroundColor: '#fff',
        boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.1)'
      }}>
        <h1 style={{ 
          marginBottom: '30px',
          color: '#333',
          textAlign: 'center'
        }}>User</h1>
        <input 
          type="email"
          placeholder="Email"
          style={{ 
            width: '100%',
            marginBottom: '15px',
            padding: '10px',
            border: '1px solid #ccc',
            borderRadius: '5px'
          }}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <input 
          type="password"
          placeholder="Password"
          style={{ 
            width: '100%',
            marginBottom: '15px',
            padding: '10px',
            border: '1px solid #ccc',
            borderRadius: '5px'
          }}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <button 
          style={{ 
            width: '100%',
            padding: '10px',
            border: 'none',
            borderRadius: '5px',
            backgroundColor: '#007bff',
            color: '#fff',
            cursor: 'pointer',
            transition: 'background-color 0.3s'
          }}
          onClick={handleLogin}
        >
          Login
        </button>
        {errorMessage && <p style={{ marginTop: '20px', textAlign: 'center', color: '#ff0000' }}>{errorMessage}</p>}
      </div>
    </div>
  );
}

export default UserPage;
