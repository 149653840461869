import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';


// Replace 'companyLogo' with the URL of your company logo
const companyLogo = 'https://www.sribalajiricemill.com/wp-content/uploads/2022/06/unnamed-13-400x81.png';

// Replace 'riceFieldImage' with your online image URL
const riceFieldImage = 'https://img.freepik.com/free-photo/green-field-tree-blue-skygreat-as-backgroundweb-banner-generative-ai_1258-158253.jpg';

const watermarkImage = 'https://infinitimart.co.in/wp-content/uploads/2024/03/infinitimart-Logo-4.png';

// Replace with the desired email address
const adminEmail = 'gsparas2k5@gmail.com';

function HomePage() {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [otp, setOtp] = useState('');
  const [otpError, setOtpError] = useState('');
  const [otpSent, setOtpSent] = useState(false);

  const openModal = async () => {
    try {
      const response = await fetch('https://sbrm-routing-078364e06806.herokuapp.com/api/sendEmailOtp', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: adminEmail }),
      });

      if (!response.ok) {
        throw new Error('Failed to send OTP');
      }

      setOtpSent(true);
      setShowModal(true);
    } catch (error) {
      console.error('Error sending OTP:', error);
      setOtpError('Failed to send OTP. Please try again.');
    }
  };

  const closeModal = () => {
    setShowModal(false);
    setOtp('');
    setOtpError('');
    setOtpSent(false);
  };

  const handleOtpChange = (e) => {
    setOtp(e.target.value);
  };

  const verifyOtp = async () => {
    try {
      const response = await fetch('https://sbrm-routing-078364e06806.herokuapp.com/api/verifyEmailOtp', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: adminEmail, otp }),
      });

      const data = await response.json();

      if (data.success) {
        navigate('/admin');// Redirect to admin login page upon successful OTP verification
      } else {
        setOtpError('Incorrect OTP. Please try again.');
      }
    } catch (error) {
      console.error('Error verifying OTP:', error);
      setOtpError('Error verifying OTP. Please try again.');
    }
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh', backgroundImage: `url(${riceFieldImage})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
      <img src={companyLogo} alt="Company Logo" style={{ width: '300px', marginBottom: '20px',}} />
      <h1 style={{ marginBottom: '30px', color: '#fff', textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)' }}>Welcome to Sri Balaji Rice Mill</h1>
      <Link to="#" style={{ marginBottom: '20px', textDecoration: 'none', color: '#fff', fontSize: '18px', fontWeight: 'bold', cursor: 'pointer', border: '2px solid #fff', borderRadius: '5px', padding: '10px 20px', backgroundColor: 'transparent', transition: 'background-color 0.3s, color 0.3s' }} onClick={openModal}>Admin</Link>
      <Link to="/user" style={{ textDecoration: 'none', color: '#fff', fontSize: '18px', fontWeight: 'bold', cursor: 'pointer', border: '2px solid #fff', borderRadius: '5px', padding: '10px 20px', backgroundColor: 'transparent', transition: 'background-color 0.3s, color 0.3s' }}>User</Link>

      {showModal && (
        <div className="modal" style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
          <div className="modal-content" style={{ width: '400px', backgroundColor: '#fff', padding: '20px', borderRadius: '10px', boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.1)' }}>
            <span className="close" onClick={closeModal} style={{ position: 'absolute', top: '10px', right: '10px', cursor: 'pointer', fontSize: '20px', color: '#888' }}>&times;</span>
            <h2 style={{ marginBottom: '20px', color: '#007bff', textAlign: 'center' }}>Enter OTP</h2>
            <input type="text" placeholder="OTP" style={{ width: '100%', marginBottom: '20px', padding: '10px', border: '1px solid #ccc', borderRadius: '5px', fontSize: '16px' }} value={otp} onChange={handleOtpChange} />
            <button onClick={verifyOtp} style={{ width: '100%', padding: '10px', border: 'none', borderRadius: '5px', backgroundColor: '#007bff', color: '#fff', fontSize: '16px', fontWeight: 'bold', cursor: 'pointer', transition: 'background-color 0.3s' }}>Verify OTP</button>
            {otpError && <p className="error" style={{ marginTop: '10px', color: '#ff0000', fontSize: '14px', textAlign: 'center' }}>{otpError}</p>}
          </div>
        </div>
      )}

      <h3 style={{position: 'absolute', bottom: '60px', right: '50px',color:"white", opacity: '0.7'}}>powered by</h3>
      <img src={watermarkImage} alt="Watermark" style={{ position: 'absolute', bottom: '10px', right: '10px', width: '200px', opacity: '0.7' }} />
    </div>
  );
}

export default HomePage;
