import React, { useState, useEffect } from 'react';
import * as XLSX from 'xlsx';

function OrderDetailsPage() {
  const [orderData, setOrderData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    const storedOrderData = localStorage.getItem('orderData');
    if (storedOrderData) {
      setOrderData(JSON.parse(storedOrderData));
    }
  }, []);

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (event) => {
      const workbook = XLSX.read(event.target.result, { type: 'binary' });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const data = XLSX.utils.sheet_to_json(sheet, { header: 1 });
      localStorage.setItem('orderData', JSON.stringify(data));
      setOrderData(data);
    };
    reader.readAsBinaryString(file);
  };

  const handleShare = (order) => {
    const message = `Order ID: ${order[1]}\nCustomer Name: ${order[3]}\nPhone: ${order[2]}\nAddress: ${order[4]}\nItem Name: ${order[6]}\nBill Amount: ${order[8]}\nPay Mode: ${order[9]}`;
    const whatsappURL = `https://wa.me/?text=${encodeURIComponent(message)}`;
    window.open(whatsappURL);
  };

  const filteredOrderData = orderData.filter(
    (row) =>
      (row[1] && row[1].toString().toLowerCase().includes(searchTerm.toLowerCase())) ||
      (row[3] && row[3].toString().toLowerCase().includes(searchTerm.toLowerCase())) ||
      (row[2] && row[2].toString().toLowerCase().includes(searchTerm.toLowerCase()))
  );

  return (
    <div style={{paddingLeft:"250px"}}>
      <h2 style={{ textAlign: 'center', marginBottom: '20px', color: '#333', fontWeight:"bold" }}>Order Details</h2>
      <input
        type="text"
        placeholder="Search..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        style={{
          marginBottom: '20px',
          padding: '10px',
          width: '100%',
          boxSizing: 'border-box',
          border: '1px solid #ccc',
          borderRadius: '6px',
          fontSize: '16px',
          outline: 'none',
        }}
      />
      <input type="file" accept=".xls,.xlsx" onChange={handleFileUpload} style={{ marginTop: '20px' }} />
      <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
        {filteredOrderData.map((row, rowIndex) => (
          <div
            key={rowIndex}
            style={{
              border: '1px solid #ccc',
              borderRadius: '8px',
              margin: '10px',
              padding: '20px',
              width: '200px',
             
              boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.1)',
              transition: 'transform 0.3s ease-in-out',
              cursor: 'pointer',
              backgroundColor: '#f9f9f9',
              position: 'relative', // Added position relative for positioning share button
            }}
            onClick={() => console.log(`Clicked on order ${row[1]}`)}
            onMouseEnter={(e) => (e.target.style.transform = 'scale(1.05)')}
            onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
          >
            <p style={{ margin: '5px 0', fontSize: '18px', fontWeight: 'bold', color: '#333' }}>Order ID: {row[1]}</p>
            <p style={{ margin: '5px 0', fontSize: '16px', color: '#555' }}>Customer Name: {row[3]}</p>
            <p style={{ margin: '5px 0', fontSize: '16px', color: '#555' }}>Phone: {row[2]}</p>
            <p style={{ margin: '5px 0', fontSize: '16px', color: '#555' }}>Address: {row[4]}</p>
            <p style={{ margin: '5px 0', fontSize: '16px', color: '#555' }}>Item Name: {row[6]}</p>
            <p style={{ margin: '5px 0', fontSize: '16px', color: '#555' }}>Bill Amount: {row[8]}</p>
            <p style={{ margin: '5px 0', fontSize: '16px', color: '#555' }}>Pay Mode: {row[9]}</p>
            <button
              onClick={() => handleShare(row)}
              style={{
                position: 'absolute',
                top: '10px',
                right: '10px',
                padding: '5px 10px',
                backgroundColor: '	#25D366',
                color: '#fff',
                border: 'none',
                borderRadius: '5px',
                cursor: 'pointer',
              }}
            >
              Share
            </button>
          </div>
        ))}
      </div>
    </div>
  );
}

export default OrderDetailsPage;
