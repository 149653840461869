import React, { useState, useRef, useEffect } from "react";
import { Container, Form, Button, Modal, Table } from "react-bootstrap";
import { GoogleApiWrapper, Marker, Map } from "google-maps-react";
import * as XLSX from "xlsx";
import axios from "axios";
import { useReactToPrint } from "react-to-print";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Draggable from 'react-draggable';
import { IoLogoWhatsapp } from 'react-icons/io';

const LocationMap = (props) => {
  const mapRef = useRef(null);
  const [markers, setMarkers] = useState([]);
  const [selectedMarkers, setSelectedMarkers] = useState([]);
  const [selectedWeight, setSelectedWeight] = useState(0);
  const [sharingAllowed, setSharingAllowed] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showModal1, setShowModal1] = useState(false);
  const [drivers, setDrivers] = useState(() => {
    const storedDrivers = localStorage.getItem("drivers");
    return storedDrivers ? JSON.parse(storedDrivers) : [];
  });
  const [driverName, setDriverName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [sharedRoutes, setSharedRoutes] = useState(() => {
    const storedRoutes = localStorage.getItem("sharedRoutes");
    return storedRoutes ? JSON.parse(storedRoutes) : [];
  });

  useEffect(() => {
    localStorage.setItem("drivers", JSON.stringify(drivers));
  }, [drivers]);

  useEffect(() => {
    localStorage.setItem("sharedRoutes", JSON.stringify(sharedRoutes));
  }, [sharedRoutes]);

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (evt) => {
      const data = evt.target.result;
      const workbook = XLSX.read(data, { type: "binary" });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const orders = XLSX.utils.sheet_to_json(worksheet);
      const newMarkers = orders.map((order, index) => {
        const coordinates = order["Coordinates"] ? order["Coordinates"].split(", ") : [];
        if (coordinates.length === 2 && !isNaN(parseFloat(coordinates[0])) && !isNaN(parseFloat(coordinates[1]))) {
          const latOffset = (Math.random() - 0.5) * 0.01; // small random offset for latitude
          const lngOffset = (Math.random() - 0.5) * 0.01; // small random offset for longitude
          return {
            lat: parseFloat(coordinates[0]) + latOffset,
            lng: parseFloat(coordinates[1]) + lngOffset,
            orderId: order["Order ID"],
            driverName: order["Full Name"],
            weight: order["Tot Wt"],
            customerName: order["FullName"],
            customerPhone: order["Cust Phone"],
            Address: order["address"],
            Pin: order["PIN"],
            ItemName: order["Item Name"],
            Quantity: order["Qty"],
            BillAmount: order["Bill Amt"],
            PaymentMode: order["Pay Mode"],
            ItemCost: order["Item_cost"],
            Discount: order["Disc"],
            DeliveryCharge: order["Del Chr"],
            BillNumber: order["Bill no"],
            MapLocations:order["GPS LOCATION"],
            Coordinate:order["Coordinates"],
            key: index,
          };
        }
        return null;
      }).filter(marker => marker !== null);
      setMarkers(newMarkers);
      fitMapBounds(newMarkers);
    };
    reader.readAsBinaryString(file);
  };

  const fitMapBounds = (markers) => {
    if (mapRef.current && markers.length > 0) {
      const bounds = new props.google.maps.LatLngBounds();
      markers.forEach((marker) => {
        bounds.extend({ lat: marker.lat, lng: marker.lng });
      });
      mapRef.current.map.fitBounds(bounds);
    }
  };

  const handleMarkerClick = (marker) => {
    const index = selectedMarkers.findIndex((m) => m.key === marker.key);
    if (index === -1) {
      setSelectedMarkers([...selectedMarkers, marker]);
      setSelectedWeight((prevWeight) => prevWeight + parseFloat(marker.weight));
      setSharingAllowed(true);
    } else {
      const updatedMarkers = selectedMarkers.filter(
        (m) => m.key !== marker.key
      );
      setSelectedMarkers(updatedMarkers);
      setSelectedWeight((prevWeight) => prevWeight - parseFloat(marker.weight));
      if (updatedMarkers.length === 0) {
        setSharingAllowed(false);
      }
    }
  };

  const shareRoute = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };
  useEffect(() => {
    // Fetch drivers from the backend when the component mounts
    const fetchDrivers = async () => {
        try {
            const response = await axios.get('https://sbrm-routing-078364e06806.herokuapp.com/api/fetchdrivers');
            setDrivers(response.data);
        } catch (error) {
            console.error('Error fetching drivers:', error);
        }
    };

    fetchDrivers();
}, []);

const handleAddDriver = async () => {
    if (driverName && phoneNumber) {
        try {
            const response = await axios.post('https://sbrm-routing-078364e06806.herokuapp.com/api/drivers', {
                name: driverName,
                phone: phoneNumber,
            });

            if (response.status === 201) {
                setDrivers([...drivers, response.data]);
                setDriverName("");
                setPhoneNumber("");
            } else {
                console.error('Failed to add driver:', response.data);
            }
        } catch (error) {
            console.error('Error adding driver:', error);
        }
    }
};

const handleDeleteDriver = async (index) => {
    const driverId = drivers[index]?._id; // Ensure _id is retrieved correctly

    if (!driverId) {
        console.error('Driver ID is undefined');
        return;
    }

    try {
        const response = await axios.delete(`https://sbrm-routing-078364e06806.herokuapp.com/api/drivers/${driverId}`);
        if (response.status === 200) {
            const updatedDrivers = [...drivers];
            updatedDrivers.splice(index, 1);
            setDrivers(updatedDrivers);
        } else {
            console.error('Failed to delete driver:', response.data);
        }
    } catch (error) {
        console.error('Error deleting driver:', error);
    }
};
  const handleDeleteRoute = (index) => {
    const updatedRoutes = [...sharedRoutes];
    updatedRoutes.splice(index, 1);
    setSharedRoutes(updatedRoutes);
    localStorage.setItem("sharedRoutes", JSON.stringify(updatedRoutes));
  };

  const handleShareWithDriver = async (driverPhone, driverName, includeEnd) => {
    // Haversine formula to calculate distance between two coordinates
    const calculateDistance = (coord1, coord2) => {
        const R = 6371; // Radius of the Earth in km
        const dLat = (coord2.lat - coord1.lat) * Math.PI / 180;
        const dLng = (coord2.lng - coord1.lng) * Math.PI / 180;
        const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
                  Math.cos(coord1.lat * Math.PI / 180) * Math.cos(coord2.lat * Math.PI / 180) *
                  Math.sin(dLng / 2) * Math.sin(dLng / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        return R * c; // Distance in km
    };

    const calculateTotalDistance = (route, startCoordinates) => {
        let totalDistance = 0;
        let previous = startCoordinates;

        for (let i = 0; i < route.length; i++) {
            totalDistance += calculateDistance(previous, route[i]);
            previous = route[i];
        }

        if (includeEnd) {
            totalDistance += calculateDistance(previous, startCoordinates); // Return to start if required
        }

        const additionalDistance = includeEnd ? 12 : 6.5;
        return totalDistance + additionalDistance;
    };

    const permute = (arr) => {
        if (arr.length === 0) return [[]];
        return arr.reduce((acc, item, i) =>
            acc.concat(permute(arr.slice(0, i).concat(arr.slice(i + 1))).map(perm => [item].concat(perm))), []);
    };

    const generateDirectionsUrl = (startCoordinates, waypoints, includeEnd) => {
        const baseUrl = 'https://www.google.com/maps/dir/?api=1';
        const origin = `${startCoordinates.lat},${startCoordinates.lng}`;
        
        const destination = includeEnd ? origin : `${waypoints[waypoints.length - 1].lat},${waypoints[waypoints.length - 1].lng}`;
        const waypointsStr = waypoints.map(wp => `${wp.lat},${wp.lng}`).join('|');
        const avoidOptions = 'highways|tolls'; // Avoid highways and tolls

        const url = `${baseUrl}&origin=${origin}&destination=${destination}&waypoints=${waypointsStr}&avoid=${avoidOptions}`;
        return url;
    };

    const waypoints = selectedMarkers.map(marker => ({
        lat: marker.lat,
        lng: marker.lng,
        ...marker,
    }));

    const startCoordinates = { lat: 12.960279680889894, lng: 77.5947116791102 };

    try {
        const permutations = permute(waypoints);

        let shortestRoute = null;
        let shortestDistance = Infinity;

        for (const route of permutations) {
            const distance = calculateTotalDistance(route, startCoordinates);
            if (distance < shortestDistance) {
                shortestDistance = distance;
                shortestRoute = route;
            }
        }

        const directionsUrl = generateDirectionsUrl(startCoordinates, shortestRoute, includeEnd);

        const sharedRoute = {
            routeLink: directionsUrl,
            driverName: driverName,
            totalDistanceKm: shortestDistance.toFixed(2),
            orderDetails: shortestRoute.map(marker => ({
                orderId: marker.orderId,
                driverName: driverName,
                weight: marker.weight,
                customerName: marker.customerName,
                customerPhone: marker.customerPhone,
                address: marker.address,
                pin: marker.pin,
                itemName: marker.itemName,
                quantity: marker.quantity,    
                billAmount: marker.billAmount,
                paymentMode: marker.paymentMode,
                itemCost: marker.itemCost,
                discount: marker.discount,
                deliveryCharge: marker.deliveryCharge,
                billNumber: marker.billNumber,
                mapLocations: marker.mapLocations,
                coordinate: marker.coordinate
            })),
        };

        setSharedRoutes([...sharedRoutes, sharedRoute]);

        const message = `Check out the optimized route map: ${directionsUrl} Total distance: ${shortestDistance.toFixed(2)} km`;
        const whatsappLink = `https://wa.me/${encodeURIComponent(driverPhone)}?text=${encodeURIComponent(message)}`;
        console.log("WhatsApp Link:", whatsappLink);
        window.open(whatsappLink);

        const remainingMarkers = markers.filter(marker => !selectedMarkers.includes(marker));
        setMarkers(remainingMarkers);
        setSelectedMarkers([]);
        setSelectedWeight(0);
        setSharingAllowed(false);
    } catch (error) {
        console.error("Optimization failed due to ", error);
    }
};


const handleSaveToDatabase = async () => {
    const orderData = sharedRoutes.map(route => ({
        routeLink: route.routeLink,
        orderDetails: route.orderDetails,
        totalWeight: route.orderDetails.reduce((total, order) => total + parseFloat(order.weight || 0), 0),
        totalDistanceKm: route.totalDistanceKm // Ensure totalDistanceKm is included in the database entry
    }));

    try {
        const response = await axios.post('https://sbrm-routing-078364e06806.herokuapp.com/api/save-order', orderData);
        if (response.status === 200) {
            alert('Order saved successfully');
        } else {
            throw new Error(`Unexpected response code: ${response.status}`);
        }
    } catch (error) {
        console.error('Error saving order:', error.response ? error.response.data : error.message);
        alert('Error saving order: ' + (error.response ? error.response.data : error.message));
    }
};


const [includeEndCoordinate, setIncludeEndCoordinate] = useState(drivers.map(() => true)); // Initialize state for checkboxes

    const handleCheckboxChange = (index) => {
        const newIncludeEndCoordinate = [...includeEndCoordinate];
        newIncludeEndCoordinate[index] = !newIncludeEndCoordinate[index];
        setIncludeEndCoordinate(newIncludeEndCoordinate);
    };


  

  const [showTable, setShowTable] = useState(false);

  // --------------------printing-screen------------------------
  const componentRef = useRef();

  // const handlePrint = useReactToPrint({
  //   content: () => componentRef.current,
  // });

  const handleGenerateExcel = () => {
    const wb = XLSX.utils.book_new();
    const wsData = sharedRoutes.map((route) => {
      return {
        "Order ID": route.orderDetails.map((order) => order.orderId),
        "Order Weight": route.orderDetails.map((order) => order.weight),
        "Customer Name": route.orderDetails.map((order) => order.customerName),
        "Customer Phone": route.orderDetails.map((order) => order.customerPhone),
        "Address": route.orderDetails.map((order) => order.Address),
        "Pin": route.orderDetails.map((order) => order.Pin),
        "Item Name": route.orderDetails.map((order) => order.ItemName),
        "Quantity": route.orderDetails.map((order) => order.Quantity),
        "Bill Amount": route.orderDetails.map((order) => order.BillAmount),
        "Payment Mode": route.orderDetails.map((order) => order.PaymentMode),
        "Item Cost": route.orderDetails.map((order) => order.ItemCost),
        "Discount": route.orderDetails.map((order) => order.Discount),
        "Delivery Charge": route.orderDetails.map((order) => order.DeliveryCharge),
        "Bill Number": route.orderDetails.map((order) => order.BillNumber),
        "MapLocation": route.orderDetails.map((order) => order.MapLocations),
        "Driver Name": route.driverName,
        "Total Weight": route.orderDetails.reduce(
          (totalWeight, order) => totalWeight + parseFloat(order.weight),
          0
        ),
        "Route Link": route.routeLink,
        "Total kms": route.totalDistanceKm,
      };
    });
  
    // Flatten the data to ensure each order ID and order weight corresponds to a route
    const flattenedData = wsData
      .map((route) => {
        const rows = [];
        let serialNumber = 1; // Initialize serial number for each dataset
        const maxLength = Math.max(route["Order ID"].length, route["Order Weight"].length);
  
        for (let i = 0; i < maxLength; i++) {
          const newRow = {};
          newRow["Sl Number"] = serialNumber++;
          newRow["Customer Phone"] = route["Customer Phone"][i] || "";
          newRow["Bill Number"] = route["Bill Number"][i] || "";
          newRow["Payment Mode"] = route["Payment Mode"][i] || "";
          newRow["MapLocation"] = route["MapLocation"][i] || "";
          newRow["Driver Name"] = route["Driver Name"];
          newRow["Total Weight"] = route["Total Weight"];
          newRow["Total kms"] = route["Total kms"];
          rows.push(newRow);
        }
  
        // Add an empty row between each data entry
        rows.push({
          "Sl Number": "",
          "Customer Phone": "",
          "Bill Number": "",
          "Payment Mode": "",
          "MapLocation": "",
          "Driver Name": "",
          "Total Weight": "",
          "Total kms": ""
        });
        rows.push({
          "Sl Number": "",
          "Customer Phone": "",
          "Bill Number": "",
          "Payment Mode": "",
          "MapLocation": "",
          "Driver Name": "",
          "Total Weight": "",
          "Total kms": ""
        });
  
        return rows;
      })
      .flat();
  
    const ws = XLSX.utils.json_to_sheet(flattenedData);
    XLSX.utils.book_append_sheet(wb, ws, "Assigned Order Details");
    XLSX.writeFile(wb, "assigned_order_details.xlsx");
  };
  
  

  return (
    <Container style={styles.container}>
      <div style={styles.mapContainer}>
      <Map
  google={props.google}
  zoom={5}
  style={styles.map}
  initialCenter={{ lat: 20.5937, lng: 78.9629 }}
  ref={mapRef}
 styles={[
  {
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#f5f5f5"
      }
    ]
  },
  {
    "elementType": "labels.icon",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#616161"
      }
    ]
  },
  {
    "elementType": "labels.text.stroke",
    "stylers": [
      {
        "color": "#f5f5f5"
      }
    ]
  },
  {
    "featureType": "administrative",
    "elementType": "geometry",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "administrative.land_parcel",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "administrative.neighborhood",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "poi",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "poi",
    "elementType": "labels.text",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "poi.business",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "road",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#ffffff"
      }
    ]
  },
  {
    "featureType": "road",
    "elementType": "labels.icon",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "road.arterial",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#757575"
      }
    ]
  },
  {
    "featureType": "road.highway",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#dadada"
      }
    ]
  },
  {
    "featureType": "road.highway",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#616161"
      }
    ]
  },
  {
    "featureType": "road.local",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#9e9e9e"
      }
    ]
  },
  {
    "featureType": "transit",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "transit.line",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#e5e5e5"
      }
    ]
  },
  {
    "featureType": "transit.station",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#eeeeee"
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#c9c9c9"
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#9e9e9e"
      }
    ]
  }
 ]}
>
  {markers.map((marker, index) => (
    <Marker
      key={marker.key}
      position={{ lat: marker.lat + index * 0.0001, lng: marker.lng + index * 0.0001 }}
      title={`Order ID: ${marker.orderId}, Weight: ${marker.weight}, CustomerName: ${marker.customerName}, CustomerPhone: ${marker.customerPhone}`}
      onClick={() => handleMarkerClick(marker)}
      icon={{
        url: selectedMarkers.some((m) => m.key === marker.key)
          ? "http://maps.google.com/mapfiles/ms/icons/yellow-dot.png"
          : "http://maps.google.com/mapfiles/ms/icons/red-dot.png",
      }}
    />
  ))}
</Map>

<Draggable>
    <div style={styles.controlsContainer}>
      <Form>
        <Form.Group controlId="formFile">
          <Form.Label>Upload Excel file</Form.Label>
          <Form.Control type="file" onChange={handleFileUpload} />
        </Form.Group>
        <div style={{ display: 'flex', alignItems: 'center', marginTop: '30px' }}>
          <Button
            variant="success"
            onClick={shareRoute}
            disabled={!sharingAllowed}
            style={{ border: "none", display: 'flex', alignItems: 'center', cursor: 'pointer', backgroundColor: 'transparent' }}
          >
            <IoLogoWhatsapp size={32} color="green" />
          </Button>
          <Button
            variant="primary"
            onClick={() => setShowModal1(true)}
            style={{
              backgroundColor: 'lightgreen',
              border: 'none',
              color: 'black',
              border: "2px solid black",
              borderRadius: "5px",
              padding: '5px',
              marginLeft: '10px', // Add some space between the two buttons
              cursor: 'pointer'
            }}
          >
            Assign
          </Button>
        </div>
      </Form>
      <div style={styles.detailsContainer}>
        <p style={styles.weightInfo}>Selected Weight: {selectedWeight}</p>
        <div>
          <table style={{ width: '100%', borderCollapse: 'collapse', margin: '20px 0', fontSize: '10px' }}>
            <thead>
              <tr>
                <th style={{ border: '1px solid #ddd', padding: '8px', backgroundColor: '#f2f2f2' }}>Order ID</th>
                <th style={{ border: '1px solid #ddd', padding: '8px', backgroundColor: '#f2f2f2' }}>Item Name</th>
                <th style={{ border: '1px solid #ddd', padding: '8px', backgroundColor: '#f2f2f2' }}>Bill No</th>
                <th style={{ border: '1px solid #ddd', padding: '8px', backgroundColor: '#f2f2f2' }}>Weight</th>
              </tr>
            </thead>
            <tbody>
              {selectedMarkers.map((marker) => (
                <tr key={marker.key}>
                  <td style={{ border: '1px solid #ddd', padding: '8px' }}>{marker.orderId}</td>
                  <td style={{ border: '1px solid #ddd', padding: '8px' }}>{marker.ItemName}</td>
                  <td style={{ border: '1px solid #ddd', padding: '8px' }}>{marker.BillNumber}</td>
                  <td style={{ border: '1px solid #ddd', padding: '8px' }}>{marker.weight}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </Draggable>
      </div>

      {/* Modal for adding and selecting drivers */}
      <Modal show={showModal} onHide={handleCloseModal} style={{ marginLeft: '650px', marginTop: '250px', backgroundColor: 'lightblue', width: '600px' }}>
            <Modal.Header style={{ backgroundColor: '#17a2b8', color: '#fff', borderBottom: 'none', borderTopLeftRadius: '10px', borderTopRightRadius: '10px', fontWeight: 'bolder' }}>
                <Modal.Title>Add Drivers</Modal.Title>
                <Button variant="secondary" onClick={handleCloseModal} style={{ backgroundColor: 'red', border: 'none', color: '#fff', marginLeft: '500px', border: "2px solid black", borderRadius: "5px", padding: '5px' }}>Close</Button>
            </Modal.Header>
            <Modal.Body style={{ backgroundColor: '#fff', padding: '20px', maxHeight: '70vh', overflowY: 'auto', borderBottomLeftRadius: '10px', borderBottomRightRadius: '10px' }}>
                <Form style={{ backgroundColor: '#f8f9fa', padding: '20px', borderRadius: '10px', boxShadow: '0 4px 8px rgba(0,0,0,0.1)', }}>
                    <Form.Group controlId="driverName" style={{ marginBottom: '20px' }}>
                        <Form.Label style={{ fontWeight: 'bold', color: '#495057' }}>Driver Name</Form.Label>
                        <Form.Control
                            type="text"
                            value={driverName}
                            onChange={(e) => setDriverName(e.target.value)}
                            style={{ width: '100%', borderRadius: '5px', padding: '10px', border: '1px solid #ced4da', fontSize: '16px' }}
                        />
                    </Form.Group>
                    <Form.Group controlId="phoneNumber" style={{ marginBottom: '20px' }}>
                        <Form.Label style={{ fontWeight: 'bold', color: '#495057' }}>Phone Number</Form.Label>
                        <Form.Control
                            type="text"
                            value={phoneNumber}
                            onChange={(e) => setPhoneNumber(e.target.value)}
                            style={{ width: '100%', borderRadius: '5px', padding: '10px', border: '1px solid #ced4da', fontSize: '16px' }}
                        />
                    </Form.Group>
                </Form>

                <Button
                    variant="success"
                    onClick={handleAddDriver}
                    style={{
                        marginRight: '10px',
                        cursor: 'pointer',
                        fontWeight: 'bold',
                        backgroundColor: '#28a745',
                        borderColor: '#28a745',
                        padding: '10px 20px',
                        borderRadius: '5px',
                        fontSize: '16px',
                        transition: 'background-color 0.3s, transform 0.3s'
                    }}
                    onMouseEnter={(e) => {
                        e.target.style.backgroundColor = '#218838';
                        e.target.style.transform = 'scale(1.05)';
                    }}
                    onMouseLeave={(e) => {
                        e.target.style.backgroundColor = '#28a745';
                        e.target.style.transform = 'scale(1)';
                    }}
                >
                    Add Driver
                </Button>

                <Table striped bordered hover style={{ marginTop: '20px', borderRadius: '10px', overflow: 'hidden', width: '600px' }}>
                    <thead style={{ backgroundColor: '#343a40', color: '#fff' }}>
                        <tr>
                            <th style={{ border: '1px solid #dee2e6', padding: '10px' }}>Driver Name</th>
                            <th style={{ border: '1px solid #dee2e6', padding: '10px' }}>Phone Number</th>
                            <th style={{ border: '1px solid #dee2e6', padding: '10px' }}>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {drivers.map((driver, index) => (
                            <tr key={index} style={{ backgroundColor: index % 2 === 0 ? '#f8f9fa' : '#e9ecef' }}>
                                <td style={{ border: '1px solid #dee2e6', padding: '10px' }}>{driver.name}</td>
                                <td style={{ border: '1px solid #dee2e6', padding: '10px' }}>{driver.phone}</td>
                                <td style={{ border: '1px solid #dee2e6', padding: '10px' }}>
                                    <Form.Check
                                        type="checkbox"
                                        label="Return Hub"
                                        checked={includeEndCoordinate[index]}
                                        onChange={() => handleCheckboxChange(index)}
                                    />
                                    <Button
                                        variant="danger"
                                        onClick={() => handleDeleteDriver(index)}
                                        style={{ marginRight: '5px', cursor: 'pointer', fontWeight: 'bold', backgroundColor: '#dc3545', borderColor: '#dc3545', borderRadius: '5px', padding: '5px 10px' }}
                                    >
                                        Delete
                                    </Button>
                                    <Button
                                        variant="primary"
                                        onClick={() => handleShareWithDriver(driver.phone, driver.name, includeEndCoordinate[index])}
                                        style={{ cursor: 'pointer', fontWeight: 'bold', backgroundColor: '#007bff', borderColor: '#007bff', borderRadius: '5px', padding: '5px 10px' }}
                                    >
                                        Share Route
                                    </Button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </Modal.Body>
        </Modal>





      {/* Table to display shared routes */}
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', marginLeft: '500px' }}>
      <Modal
        show={showModal1}
        onHide={() => setShowModal1(false)}
        style={{ maxWidth: '60%', border: 'none', borderRadius: '10px', overflow: 'hidden', marginLeft: '400px', marginTop: '250px', backgroundColor: '#17a2b8', border: '2px solid black' }}
      >
        <Modal.Header closeButton style={{ backgroundColor: '#17a2b8', color: '#fff', borderBottom: 'none', fontWeight: "bolder" }}>
          <Modal.Title>Assigned order details</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ backgroundColor: '#fff', padding: '20px', borderTopLeftRadius: '10px', borderTopRightRadius: '10px', maxHeight: '70vh', overflowY: 'auto' }}>
          <div>
          <Table
  id="table"
  striped
  bordered
  hover
  style={{
    border: '1px solid #dee2e6',
    borderRadius: '10px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  }}
>
  <thead style={{ backgroundColor: '#343a40', color: '#fff' }}>
    <tr>
      <th style={{ padding: '15px', verticalAlign: 'middle' }}>Sl No</th>
      <th style={{ padding: '1px', verticalAlign: 'middle', width: "300px" }}>Bill No</th>
      <th style={{ padding: '15px', verticalAlign: 'middle' }}>Phone number</th>
      <th style={{ padding: '15px', verticalAlign: 'middle' }}>Name</th>
      <th style={{ padding: '15px', verticalAlign: 'middle' }}>Payment Comment</th>
      <th style={{ padding: '15px', verticalAlign: 'middle' }}>Gmap Location</th>
      <th style={{ padding: '15px', verticalAlign: 'middle' }}>Total Weight</th>
      <th style={{ padding: '15px', verticalAlign: 'middle' }}>Total kms</th>
      <th style={{ padding: '15px', verticalAlign: 'middle' }}>Action</th>
    </tr>
  </thead>
  <tbody>
    {sharedRoutes.map((route, index) => (
      <tr
        key={index}
        style={{
          backgroundColor: index % 2 === 0 ? '#f8f9fa' : '#e9ecef',
          borderRadius: '10px',
        }}
      >
        <td style={{ verticalAlign: 'middle' }}>{index + 1}</td>
        <td style={{ verticalAlign: 'middle', minWidth: '100px' }}>
          {route.orderDetails.map((order, orderIndex) => (
            <div
              key={orderIndex}
              style={{
                backgroundColor: orderIndex % 2 === 0 ? '#ffffff' : '#f0f0f0',
                padding: '10px',
                borderBottom: '1px solid #dee2e6',
              }}
            >
              {orderIndex + 1}. {order.BillNumber}
            </div>
          ))}
        </td>
        <td style={{ verticalAlign: 'middle' }}>
          {route.orderDetails.map((order, orderIndex) => (
            <div
              key={orderIndex}
              style={{
                backgroundColor: orderIndex % 2 === 0 ? '#ffffff' : '#f0f0f0',
                padding: '10px',
                borderBottom: '1px solid #dee2e6',
              }}
            >
              {order.customerPhone}
            </div>
          ))}
        </td>
        <td
          style={{
            verticalAlign: 'middle',
            minWidth: '300px',
            textAlign: 'left',
          }}
        >
          {route.orderDetails.map((order, orderIndex) => (
            <div
              key={orderIndex}
              style={{
                whiteSpace: 'pre-line',
                backgroundColor: orderIndex % 2 === 0 ? '#ffffff' : '#f0f0f0',
                padding: '10px',
                borderBottom: '1px solid #dee2e6',
              }}
            >
              {order.customerName}
            </div>
          ))}
        </td>
        <td style={{ verticalAlign: 'middle' }}>
          {route.orderDetails.map((order, orderIndex) => (
            <div
              key={orderIndex}
              style={{
                backgroundColor: orderIndex % 2 === 0 ? '#ffffff' : '#f0f0f0',
                padding: '10px',
                borderBottom: '1px solid #dee2e6',
              }}
            >
              {order.PaymentMode}
            </div>
          ))}
        </td>
        <td style={{ verticalAlign: 'middle' }}>
          <button
            onClick={() => window.open(route.routeLink, '_blank', 'noopener,noreferrer')}
            style={{
              color: '#fff',
              backgroundColor: '#007bff',
              border: 'none',
              padding: '5px 10px',
              textDecoration: 'none',
              cursor: 'pointer',
              borderRadius: '4px',
              fontSize: '16px',
            }}
          >
            Gmap loc
          </button>
        </td>
        <td style={{ verticalAlign: 'middle' }}>
          {route.orderDetails.reduce(
            (totalWeight, order) => totalWeight + parseFloat(order.weight),
            0
          )}
        </td>
        <td style={{ verticalAlign: 'middle' }}>{route.totalDistanceKm} km</td>
        <td style={{ verticalAlign: 'middle' }}>
          <Button
            variant="danger"
            onClick={() => handleDeleteRoute(index)}
            style={{
              backgroundColor: '#dc3545',
              borderColor: '#dc3545',
              cursor: 'pointer',
              fontWeight: 'bold',
              borderRadius: '5px',
              padding: '5px 10px',
            }}
          >
            Delete
          </Button>
        </td>
      </tr>
    ))}
  </tbody>
</Table>


          </div>
        </Modal.Body>
        <Modal.Footer style={{ backgroundColor: '#fff', padding: '20px', borderBottomLeftRadius: '10px', borderBottomRightRadius: '10px' }}>
          <Button
            variant="secondary"
            onClick={() => setShowModal1(false)}
            style={{ marginRight: '10px', cursor: 'pointer', backgroundColor: '#6c757d', borderColor: '#6c757d', fontWeight: 'bold', borderRadius: '5px', padding: '10px 20px' }}
          >
            Close
          </Button>
          <Button
            variant="success"
            onClick={handleGenerateExcel}
            style={{ marginRight: '10px', cursor: 'pointer', backgroundColor: '#28a745', borderColor: '#28a745', fontWeight: 'bold', borderRadius: '5px', padding: '10px 20px' }}
          >
            Generate Excel sheet
          </Button>
          {/* <Button
            variant="info"
            onClick={handlePrint}
            style={{ cursor: 'pointer', backgroundColor: '#17a2b8', borderColor: '#17a2b8', fontWeight: 'bold', borderRadius: '5px', padding: '10px 20px' }}
          >
            Print
          </Button> */}
          <Button
            variant="primary"
            onClick={handleSaveToDatabase}
            style={{ cursor: 'pointer', backgroundColor: '#007bff', borderColor: '#007bff', fontWeight: 'bold', borderRadius: '5px', padding: '10px 20px', marginLeft: '10px' }}
          >
            Save to Database
          </Button>
        </Modal.Footer>
      </Modal>
    </div>




    </Container>
  );
};

const styles = {
  container: {
    marginTop: "0px",
    marginLeft: "0px",
    marginRight: "auto",
    maxWidth: "100%",
  },
  mapContainer: {
    position: "relative",
    width: "120%",
    height: "100vh",
    top: "-30px",
  },
  map: {
    width: "100%",
    height: "100%",
  },
  controlsContainer: {
    position: 'absolute', // Make it floating
    zIndex: 1000, // Ensure it's on top
    backgroundColor: 'white', // Background color for better visibility
    padding: '20px', // Padding for some spacing inside the container
    border: '1px solid #ddd', // Border for better visibility
    borderRadius: '8px', // Rounded corners
    boxShadow: '0 2px 10px rgba(0,0,0,0.1)', // Slight shadow for a floating effect
  },
  detailsContainer: {
    marginTop: '20px', // Some margin between the form and details container
  },
  weightInfo: {
    fontWeight: 'bold', // Bold text for weight info
  },
  input: {
    marginBottom: "10px",
  },
  button: {
    backgroundColor: "#25D366",
    color: "#fff",
    border: "none",
    padding: "8px 15px",
    borderRadius: "3px",
    cursor: "pointer",
    marginRight: "10px",
    fontSize: "16px",
    fontWeight: "550",
  },
  detailsContainer: {
    marginTop: "10px",
    backgroundColor: "#f9f9f9",
    padding: "10px",
    borderRadius: "5px",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
  },
  weightInfo: {
    color: "#333",
    marginBottom: "10px",
  },
  selectedOrderIds: {
    color: "#333",
    marginBottom: "5px",
  },
  selectedOrderList: {
    listStyleType: "none",
    padding: "0",
    margin: "0",
  },
  selectedOrderListItem: {
    marginBottom: "5px",
  },
};

export default GoogleApiWrapper({
  apiKey: "AIzaSyAnuto10z9skZi2GPJXU_ZSL6hObDocsF0",
})(LocationMap);
