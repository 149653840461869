import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'; // Import Routes

import HomePage from './components/HomePage';
import AdminPage from './components/AdminPage';
import UserPage from './components/UserPage';
import DashboardPage from './components/DashboardPage';

function App() {
  return (
    <Router>
      <Routes> {/* Wrap Routes around your Route components */}
        <Route path="/" element={<HomePage />} />
        <Route path="/admin" element={<AdminPage />} />
        <Route path="/user" element={<UserPage />} />
        <Route path="/dashboard" element={<DashboardPage />} />
      </Routes>
    </Router>
  );
}

export default App;
