import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const Dashboard = () => {
  return (
    <Container fluid style={{ backgroundColor: "white", minHeight: "100vh", fontFamily: "Arial, sans-serif", color: "#333", padding: "10px",paddingLeft:"250px" }}>
      <Row className="justify-content-center">
        <Col md={6}>
          <h1 style={{ fontSize: "2rem", marginBottom: "20px", color: "#007bff", fontWeight: "bold",paddingLeft:"80px" }}>Sri Balaji Rice Mill</h1>
          <p style={{ fontSize: "1rem", marginBottom: "20px",paddingLeft:"80px" }}>
            Sri Balaji Rice Mill was established in the year 1980 (12/12/1980) in Bellary district, Karnataka. The entire plant is spread over 5 acres of land.
          </p>
          <div style={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)", gap: "10px" }}>
            <img src="https://www.sribalajiricemill.com/wp-content/uploads/2022/09/sb-1.jpg" alt="Rice Mill 1" style={{ width: "80%", height: "200px", borderRadius: "8px" ,paddingLeft:"80px", borderTopLeftRadius:"8px"}} />
            <img src="https://sribalajiricemill.com/wp-content/uploads/2022/09/sb-2.jpg" alt="Rice Mill 2" style={{ width: "80%", height: "200px", borderRadius: "8px" }} />
            <img src="https://www.sribalajiricemill.com/wp-content/uploads/2022/08/21-Storage-Silos-400x266.jpg" alt="Rice Mill 3" style={{ width: "80%", height: "200px", borderRadius: "8px",paddingLeft:"80px" }} />
            <img src="https://m.economictimes.com/thumb/msid-74822211,width-1200,height-900,resizemode-4,imgsize-258618/rice-agencies.jpg" alt="Rice Mill 4" style={{ width: "80%", height: "200px", borderRadius: "8px" }} />
          </div>
          <p style={{ fontSize: "1rem", marginTop: "20px",paddingLeft:"80px" }}>
            "Our Vision is to provide our customers with the best quality of rice in terms of its cooking, taste, cleanliness, and produced in the most hygienic conditions." - Sri Balaji Rice Mill
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default Dashboard;
